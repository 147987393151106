object RoutingPaths {
    const val API = "/api"
    const val LOGIN = "/login"

    const val SHOPAPOTHEKE_PRODUCTS_DE = "/shop-apotheke-products-germany"
    const val SHOPAPOTHEKE_PRODUCTS_AT = "/shop-apotheke-products-austria"
    const val SHOPAPOTHEKE_PRODUCTS_IT = "/shop-apotheke-products-italy"
    const val KAUFLAND_PRODUCTS_DE = "/kaufland-products-germany"
    const val KAUFLAND_PRODUCTS_CZ = "/kaufland-products-czech-republic"
    const val KAUFLAND_PRODUCTS_DE_LSE = "/kaufland-products-germany-lse"
    const val MEDIA_MARKT_PRODUCTS_DE = "/media-markt-products-germany"
    const val MEDIA_MARKT_PRODUCTS_IT = "/media-markt-products-italy"
    const val MEDIA_MARKT_PRODUCTS_AT = "/media-markt-products-austria"
    const val MEDIA_MARKT_PRODUCTS_ES = "/media-markt-products-spain"

    const val SEARCH_JTL_PRODUCT = "/search-jtl-product/{searchTerm}"
    const val SET_STOCK_ZERO = "/set-stock-zero"

    const val JTL_PRODUCT_DETAILS = "/jtl-product-details"
    const val CREATE_USER = "/create-user"
    const val CONFIRM_USER = "/confirm-user"
    const val DELETE_USER = "/delete-user"

    const val GET_ALL_NOT_SYNCED_ORDERS_SMART = "/get-all-not-synced-orders-smart"
    const val GET_ALL_NOT_SYNCED_ORDERS_NERO = "/get-all-not-synced-orders-nero"
    const val GET_ALL_NOT_SYNCED_ORDERS_LSE = "/get-all-not-synced-orders-lse"

    const val ORDER_CREATED_JTL = "/order-created-jtl/{externalOrderId}"
    const val ORDER_TRACKING_INFO = "/order-tracking-info"
    const val UPLOAD_ORDER_FILE = "/upload-order-file"
    const val CANCEL_ORDER = "/cancel-order/{jtlOrderId}"

    const val GET_ALL_NOT_SYNCED_ORDER_INFOS = "/get-all-not-synced-order-infos"

    const val EMAIL_STOCK = "/email-stock"

    const val SYNC_STATUS = "/sync-status"
    const val SYNC_SET_ACTIVE = "/sync-set-active"
    const val SYNC_SET_INACTIVE = "/sync-set-inactive"

    const val GET_SHOPS = "/get-shops"
    const val SET_SYNC_ORDERS = "/set-sync-orders"
    const val SET_SYNC_TRACKING_INFOS = "/set-sync-tracking-infos"
    const val SET_SYNC_DOCUMENTS = "/set-sync-documents"
    const val SET_SYNC_STOCK = "/set-sync-stock"

    const val SET_ORDER_TRACKING_SYNCED = "/set-order-tracking-synced"

    const val GET_RELATION_REPRICER = "/get-relation-reprice"

    const val KASSA_PRODUCTS = "/kassa-products"
    const val KASSA_PRODUCTS_DETAILS = "/kassa-products-details"
    const val CREATE_KASSA_ORDER = "/create-kassa-order"
}
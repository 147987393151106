package react.overview

import connectJtlProduct
import emotion.css.keyframes
import emotion.react.css
import extensions.getStock
import extensions.setCssForTdTr
import extensions.toFormatedDate
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import model.Relation
import model.SearchJtlItem
import model.Shop
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import react.dom.svg.ReactSVG
import react.useEffect
import react.useState
import searchJtlProducts
import setStockMaxHundred
import setStockZero
import web.cssom.*
import web.html.HTMLTableCellElement
import web.html.InputType

external interface RelationItemProps : Props {
    var relation: Relation
    var shop: Shop
    var scope: CoroutineScope
    var refreshData: () -> Unit
}

val RelationItem = FC<RelationItemProps> { props ->
    val relation = props.relation
    val shop = props.shop
    val scope = props.scope
    val refreshData = props.refreshData

    val skuJtlId = "skuJtl-${relation.itemSku}"
    val (jtlSku, setJtlSku) = useState(relation.jtl?.itemSku.orEmpty())
    val (stockZeroState, setStockZeroState) = useState(relation.stockZero)
    val (stockMaxHundredState, setStockMaxHundredState) = useState(relation.stockMaxHundred)

    var showModal by useState(false)

    var searchResults by useState(emptyList<SearchJtlItem>())

    var hoverTimeoutId by useState(0)
    var hoverImageData by useState<String?>(null)

    var isLoading by useState(false)

    var selectedItem by useState<String?>(null)

    var touchStartTime by useState(0.0)

    useEffect(relation) {
        setJtlSku(relation.jtl?.itemSku.orEmpty())
    }

    td {
        setCssForTdTr(backgroundRed = selectedItem == relation.itemSku)

        +relation.itemName
    }
    td {
        setCssForTdTr(backgroundRed = selectedItem == relation.itemSku)

        +relation.itemSku
    }
    td {
        setCssForTdTr(
            center = true,
            backgroundRed = selectedItem == relation.itemSku
        )
        input {
            css {
                width = 100.pct
                boxSizing = BoxSizing.borderBox
            }
            key = "${shop.shopName}-${relation.itemSku}"
            id = skuJtlId
            type = InputType.text
            value = jtlSku
            placeholder = "SKU (WAWI)"
            onChange = { event ->
                setJtlSku(event.target.value)
                val inputElement = event.target
                val parentTdElement = inputElement.parentElement as HTMLTableCellElement
                parentTdElement.style.width = "${inputElement.value.length}ch"
            }
            onFocus = {
                selectedItem = relation.itemSku
            }
            onBlur = {
                if (!showModal) {
                    selectedItem = null
                }
            }
            onDoubleClick = {
                selectedItem = relation.itemSku
                showModal = true
            }
            onTouchStart = {
                touchStartTime = window.performance.now()
            }

            onTouchEnd = {
                val touchDuration = window.performance.now() - touchStartTime
                if (touchDuration > 500) {
                    selectedItem = relation.itemSku
                    showModal = true
                }
            }
        }
    }
    td {
        if (relation.jtl == null) {
            setCssForTdTr(
                center = true,
                backgroundRed = selectedItem == relation.itemSku
            )
            +"-"
        } else {
            setCssForTdTr(backgroundRed = selectedItem == relation.itemSku)
            +relation.jtl.itemName
        }
    }
    td {
        setCssForTdTr(
            center = true,
            backgroundRed = selectedItem == relation.itemSku
        )

        +relation.jtl?.stock.getStock()
    }
    td {
        setCssForTdTr(
            center = true,
            backgroundRed = selectedItem == relation.itemSku
        )
        input {
            type = InputType.checkbox
            checked = stockZeroState
            onDoubleClick = {
                setStockZeroState(!stockZeroState)
                scope.launch {
                    setStockZero(
                        externalSku = relation.itemSku,
                        shop = shop,
                        stockZero = !stockZeroState
                    )
                    refreshData()
                }
            }
        }
    }
    td {
        setCssForTdTr(
            center = true,
            backgroundRed = selectedItem == relation.itemSku
        )
        input {
            type = InputType.checkbox
            checked = stockMaxHundredState
            onDoubleClick = {
                setStockMaxHundredState(!stockMaxHundredState)
                scope.launch {
                    setStockMaxHundred(
                        externalSku = relation.itemSku,
                        shop = shop,
                        stockMaxHundred = !stockMaxHundredState
                    )
                    refreshData()
                }
            }
        }
    }
    td {
        if (relation.lastUpdate == null) {
            setCssForTdTr(
                center = true,
                backgroundRed = selectedItem == relation.itemSku
            )
            +"-"
        } else {
            setCssForTdTr(backgroundRed = selectedItem == relation.itemSku)
            +relation.lastUpdate.toFormatedDate()
        }
    }
    td {
        setCssForTdTr(
            center = true,
            backgroundRed = selectedItem == relation.itemSku
        )
        if (relation.jtl?.user == null) {
            +"-"
        } else {
            +relation.jtl.user
        }
    }
    td {
        css {
            paddingLeft = 12.pt
            if (selectedItem == relation.itemSku) {
                backgroundColor = Color("#FF0000")
            }
        }
        button {
            +"Speichern"
            onClick = {
                val skuJtl = (document.getElementById(skuJtlId) as? HTMLInputElement)?.value
                if (skuJtl != null) {
                    scope.launch {
                        connectJtlProduct(
                            skuJtl = skuJtl,
                            relation = relation,
                            shop = shop
                        )
                        refreshData()
                    }
                }
            }
        }
    }

    if (showModal) {
        div {
            css {
                position = Position.fixed
                top = 50.pct
                left = 50.pct
                transform = translate((-50).pct, (-50).pct)
                width = 50.vw
                height = 50.vh
                backgroundColor = Color("#FFFFFF")
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                border = Border(5.px, LineStyle.solid, Color("#D3D3D3"))
                borderRadius = 10.px
            }
            if (isLoading) {
                val spin = keyframes {
                    from {
                        transform = rotate(0.deg)
                    }
                    to {
                        transform = rotate(360.deg)
                    }
                }

                div {
                    css {
                        position = Position.fixed
                        top = 0.px
                        left = 0.px
                        width = 100.vw
                        height = 100.vh
                        transform = translate((-25).pct, (-25).pct)
                        backgroundColor = Color("#00000080")
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                    }
                    ReactSVG.svg {
                        width = 50.0
                        height = 50.0
                        viewBox = "0 0 50 50"

                        ReactSVG.circle {
                            cx = 25.0
                            cy = 25.0
                            r = 20.0
                            fill = "none"
                            strokeWidth = 4.0
                            stroke = "#000000"
                            strokeDasharray = "80"
                            strokeDashoffset = "0"
                            css {
                                transformOrigin = TransformOrigin(GeometryPosition.center, GeometryPosition.center)
                                animation = Animation(
                                    name = spin,
                                    duration = 2.s
                                )
                                animationIterationCount = AnimationIterationCount.infinite
                            }
                        }
                    }
                }
            }
            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = AlignItems.center
                    width = 100.pct
                    padding = 20.px
                }
                div {
                    css {
                        padding = 20.px
                        fontSize = 1.5.em
                    }
                    +"Produkt suchen"
                }
                div {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = AlignItems.center
                    }
                    input {
                        css {
                            width = 150.pct
                        }
                        id = "searchInput"
                        type = InputType.text
                        placeholder = "Suche..."
                        onKeyDown = {
                            if (it.key == "Enter") {
                                scope.launch {
                                    val searchTerm =
                                        (document.getElementById("searchInput") as? HTMLInputElement)?.value
                                    searchTerm?.let { term ->
                                        isLoading = true
                                        searchResults = searchJtlProducts(
                                            searchTerm = term,
                                            shop = shop
                                        )
                                        isLoading = false
                                    }
                                }
                            }
                        }
                    }
                    button {
                        +"Suchen"
                        onClick = {
                            scope.launch {
                                val searchTerm = (document.getElementById("searchInput") as? HTMLInputElement)?.value
                                searchTerm?.let {
                                    isLoading = true
                                    searchResults = searchJtlProducts(
                                        searchTerm = it,
                                        shop = shop
                                    )
                                    isLoading = false
                                }
                            }
                        }
                    }
                }
                div {
                    css {
                        margin = 1.em
                        overflow = Overflow.scroll
                        maxHeight = 32.vh
                    }
                    if (searchResults.isEmpty()) {
                        +"Keine Ergebnisse"
                    } else {
                        table {
                            css {
                                marginTop = 8.px
                                borderCollapse = BorderCollapse.collapse
                                width = 100.pct
                            }
                            thead {
                                tr {
                                    th { +"SKU" }
                                    th { +"Produktname" }
                                }
                            }
                            tbody {
                                searchResults.forEachIndexed { index, searchJtlItem ->
                                    tr {
                                        if (index % 2 == 0) {
                                            css {
                                                backgroundColor = Color("#dddddd")
                                            }
                                        }
                                        onMouseEnter = {
                                            hoverTimeoutId = window.setTimeout({
                                                scope.launch {
                                                    hoverImageData = searchJtlItem.imageBase64
                                                }
                                            }, 1000)
                                        }
                                        onMouseLeave = {
                                            window.clearTimeout(hoverTimeoutId)
                                            hoverImageData = null
                                        }

                                        onDoubleClick = {
                                            setJtlSku(searchJtlItem.itemSku)
                                            showModal = false
                                        }

                                        onTouchStart = {
                                            touchStartTime = window.performance.now()
                                        }

                                        onTouchEnd = {
                                            val touchDuration = window.performance.now() - touchStartTime
                                            if (touchDuration > 500) {
                                                setJtlSku(searchJtlItem.itemSku)
                                                showModal = false
                                            }
                                        }
                                        td {
                                            css {
                                                padding = 3.px
                                            }
                                            +searchJtlItem.itemSku
                                        }
                                        td {
                                            css {
                                                padding = 3.px
                                            }
                                            +searchJtlItem.itemName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (hoverImageData != null) {
                div {
                    css {
                        position = Position.fixed
                        top = 50.pct
                        left = 50.pct
                        transform = translate((-50).pct, (-50).pct)
                        backgroundColor = Color("#FFFFFF")
                    }
                    img {
                        src = "data:image/jpeg;base64,${hoverImageData}"
                    }
                }
            }
            div {
                css {
                    padding = 20.px
                }
                button {
                    +"Beenden"
                    onClick = {
                        showModal = false
                        selectedItem = null
                    }
                }
            }
        }
    }

    useEffect(listOf(showModal)) {
        if (showModal) {
            val searchInput = document.getElementById("searchInput") as? HTMLInputElement
            searchInput?.focus()
        }
    }
}
package model

import kotlinx.serialization.Serializable

@Serializable
data class Relation(
    val itemName: String,
    val itemSku: String,
    val jtl: JTL?,
    val lastUpdate: Long?,
    val stockZero: Boolean,
    val stockMaxHundred: Boolean
) {
    @Serializable
    data class JTL(
        val itemSku: String,
        val itemName: String,
        val stock: Int?,
        val user: String
    )
}
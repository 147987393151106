import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import model.*
import model.Shop.*

val jsonClient = HttpClient(Js) {
    followRedirects = false
    install(ContentNegotiation) {
        json()
    }
    /**
     * comment when released
     */
    install(Logging)
}

suspend fun login(user: UserLogin): String? {
    val response = jsonClient.post("/login") {
        contentType(ContentType.Application.Json)
        setBody(user)
    }
    return if (response.status == HttpStatusCode.Forbidden) {
        null
    } else {
        response.body()
    }
}

suspend fun getShopProducts(shop: Shop): List<Relation> {
    return when (shop) {
        SHOP_APOTHEKE_DE -> jsonClient.get(RoutingPaths.API + RoutingPaths.SHOPAPOTHEKE_PRODUCTS_DE).body()
        SHOP_APOTHEKE_AT -> jsonClient.get(RoutingPaths.API + RoutingPaths.SHOPAPOTHEKE_PRODUCTS_AT).body()
        SHOP_APOTHEKE_IT -> jsonClient.get(RoutingPaths.API + RoutingPaths.SHOPAPOTHEKE_PRODUCTS_IT).body()
        MEDIA_MARKT_DE -> jsonClient.get(RoutingPaths.API + RoutingPaths.MEDIA_MARKT_PRODUCTS_DE).body()
        MEDIA_MARKT_IT -> jsonClient.get(RoutingPaths.API + RoutingPaths.MEDIA_MARKT_PRODUCTS_IT).body()
        MEDIA_MARKT_AT -> jsonClient.get(RoutingPaths.API + RoutingPaths.MEDIA_MARKT_PRODUCTS_AT).body()
        MEDIA_MARKT_ES -> jsonClient.get(RoutingPaths.API + RoutingPaths.MEDIA_MARKT_PRODUCTS_ES).body()
        KAUFLAND_DE -> jsonClient.get(RoutingPaths.API + RoutingPaths.KAUFLAND_PRODUCTS_DE).body()
        KAUFLAND_CZ -> jsonClient.get(RoutingPaths.API + RoutingPaths.KAUFLAND_PRODUCTS_CZ).body()
        KAUFLAND_AT -> jsonClient.get(RoutingPaths.API + RoutingPaths.KAUFLAND_PRODUCTS_AT).body()
        KAUFLAND_LSE_DE -> jsonClient.get(RoutingPaths.API + RoutingPaths.KAUFLAND_PRODUCTS_DE_LSE).body()
        CONRAD_B2B_AT -> jsonClient.get(RoutingPaths.API + RoutingPaths.CONRAD_B2B_PRODUCTS_AT).body()
        BIGBANG_SI -> jsonClient.get(RoutingPaths.API + RoutingPaths.BIGBANG_PRODUCTS_SI).body()
    }
}

suspend fun connectJtlProduct(skuJtl: String, relation: Relation, shop: Shop) {
    jsonClient.post(RoutingPaths.API + RoutingPaths.JTL_PRODUCT_DETAILS) {
        contentType(ContentType.Application.Json)
        setBody(relation)

        url {
            parameters.append(QUERY_PARAM_SHOP, shop.name)
            parameters.append(QUERY_PARAM_SKU, skuJtl)
        }
    }
}

suspend fun searchJtlProducts(searchTerm: String, shop: Shop): List<SearchJtlItem> =
    jsonClient.get(RoutingPaths.API + RoutingPaths.SEARCH_JTL_PRODUCT.replace("{searchTerm}", searchTerm)) {
        url {
            parameters.append(
                name = QUERY_PARAM_SHOP,
                value = shop.name
            )
        }
    }.body()

suspend fun setStockZero(externalSku: String, shop: Shop, stockZero: Boolean) {
    jsonClient.post(RoutingPaths.API + RoutingPaths.SET_STOCK_ZERO) {
        contentType(ContentType.Application.Json)
        setBody(
            StockZeroBody(
                externalSku = externalSku,
                shop = shop.name,
                stockZero = stockZero
            )
        )
    }
}

suspend fun setStockMaxHundred(externalSku: String, shop: Shop, stockMaxHundred: Boolean) {
    jsonClient.post(RoutingPaths.API + RoutingPaths.SET_STOCK_MAX_HUNDRED) {
        contentType(ContentType.Application.Json)
        setBody(
            StockMaxHundredBody(
                externalSku = externalSku,
                shop = shop.name,
                stockMaxHundred = stockMaxHundred
            )
        )
    }
}

suspend fun logout() {
    jsonClient.get("/logout")
}